import { DateTime } from "luxon";
import { useParams, useSearchParams } from "react-router-dom";
import { KeyValuePair } from "../../../common/KeyValuePair";
import SubmitButton from "../../../components/Button/SubmitButton";
import SettingFieldDate from "../../../components/SettingFieldDate/SettingFieldDate";
import SettingFieldNumber from "../../../components/SettingFieldNumber/SettingFieldNumber";
import SettingFieldSelect from "../../../components/SettingFieldSelect/SettingFieldSelect";
import SettingsContainer from "../../../components/SettingsContainer/SettingsContainer";
import StepProgressBar from "../../../components/StepProgressBar/StepProgressBar";
import useConfigureViewModel from "./ConfigureViewModel";
import "./plans.css";
import useAuthService from "../../../services/auth/Auth0AuthService";
import { useEffect } from "react";

const options: KeyValuePair[] = [
  ["3 Months", "ThreeMonths"],
  ["1 Year", "Annual"]
];

const ConfigurePlan = () => {
  const [searchParams] = useSearchParams();
  const { organizationId } = useParams();
  const authService = useAuthService();

  let planCode = searchParams.get("planCode") ?? "High-Performance";
  let vm = useConfigureViewModel({
    planCode: planCode,
    authService: authService
  }, {
    rowerCount: 25,
    subscriptionLength: "Annual",
    subscriptionStartDate: DateTime.now().toISODate()
  });

  useEffect(() => vm.init(), [vm]);
  
  return (
    <>
      <StepProgressBar
        stepNames={["CHOOSE","CONFIGURE","CHECKOUT"]}
        currentIndex={1}
        />
      <h1 className="center">Configure Plan Options: {vm.planTypeFriendlyName}</h1>
      <p className="subtitle center"></p>
      <form action="https://rowhero-api-prod.azurewebsites.net/v1/chargebee/checkout" method="POST">
        <input type="hidden" name="accessToken" value={vm.accessToken} />
        <input type="hidden" name="organizationId" value={organizationId} />
        <input type="hidden" name="planType" value={vm.planType} />
        <SettingsContainer
          title="Plan Options"
          className="margin-bottom">
          <SettingFieldNumber
            title="Number of Rowers"
            ariaLabel="Number of Rowers"
            inputName="rowerCount"
            required
            defaultValue={vm.rowerCount}
            onChange={vm.onRowerCountChanged} />
          <SettingFieldSelect
            title="Subscription Length"
            ariaLabel="Subscription Length"
            inputName="subscriptionLength"
            options={options}
            required
            defaultValue={vm.subscriptionLength}
            onChange={vm.onSubscriptionLengthChanged} />
          <SettingFieldDate
            title="Subscription Start Date"
            ariaLabel="Subscription Start Date"
            inputName="subscriptionStartDate"
            required
            minDate={vm.minStartDate}
            defaultValue={vm.subscriptionStartDate}
            onChange={vm.onSubscriptionStartDateChanged}/>
        </SettingsContainer>
        <SubmitButton importance="primary" value="CHECKOUT" />
      </form>
      
    </>
  );
}

export default ConfigurePlan;