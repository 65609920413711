import StepProgressBar from "../../../components/StepProgressBar/StepProgressBar";
import PlanSelection from "./components/PlanSelection/PlanSelection";
import "./plans.css";

const essentials = [
  "Get ranked team erg data live",
  "Schedule on-your-own workouts",
  "Create tight feedback loops for your athletes to learn faster",
  "Track PRs/PBs for every piece",
  "Get more speed and technique metrics for your team",
  "Use stroke-by-stroke graphs to check pacing and off-strokes",
  "Export comprehensive data to Excel",
];

const highPerformance = [
  "Everything in Essentials",
  "See average, max heart rate for each workout",
  "Set personalized heart rate zones based on % Max HR",
  "Check athletes performed the workout at the right intensity",
  "Check team-wide technique with force curve metrics",
  "Identify boat-movers by setting peak force % targets for your team",
];

const BillingPlanIndex = () => {
  return (
    <>
      <StepProgressBar
        stepNames={["CHOOSE","CONFIGURE","CHECKOUT"]}
        currentIndex={0}
        />
      <h1 className="center">RowHero Plan Selection</h1>
      <p className="subtitle center">Choose your plan. Plan changes are processed depending on when you set the new subscription to start (on the next page).</p>
      <div id="plan-selection-container">
        <PlanSelection
          planName="Essentials"
          planCode="Essentials"
          byline="Collect erg data instantly and motivate your team by showing them progress"
          features={essentials} />
        <PlanSelection
          planName="High Performance"
          planCode="High-Performance"
          byline="Use workout intensity and technique data to drive training"
          features={highPerformance}
          popular />
      </div>
    </>
  )
};

export default BillingPlanIndex;