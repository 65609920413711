import PlanFeatureList from "../PlanFeatureList/PlanFeatureList";
import Button from "../../../../../components/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback } from "react";

interface PlanSelectionProps {
  planName: string;
  planCode: string;
  byline: string;
  features: string[];
  popular?: boolean;
}

const PlanSelection = (props: PlanSelectionProps) => {
  const navigate = useNavigate();
  const { organizationId } = useParams();

  const onSelectTapped = useCallback(() => {
    navigate(`/organizations/${organizationId}/plans/configure?planCode=${props.planCode}`)
  }, [organizationId, props.planCode, navigate]);

  return (
    <div className="plan-selection-host">
      <div className="plan-selection">
        <h2>{props.planName}</h2>
        <p className="plan-selection-byline">{props.byline}</p>
        <PlanFeatureList
          features={props.features} />
        <Button importance="primary" onClick={onSelectTapped}>
          SELECT
        </Button>
        
      </div>
    {props.popular && (
        <div className="plan-popular-ribbon-container">
          <div className="plan-popular-ribbon">
            POPULAR
          </div>
        </div>
      )}
    </div>
  );
};

export default PlanSelection;