import { useCallback, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useEventBus from "../../common/events/EventBus";
import SettingRow from "../../components/SettingRow/SettingRow";
import SettingsContainer from "../../components/SettingsContainer/SettingsContainer";
import GlobalConfig from "../../config/GlobalConfig";
import useBillingViewModel from "./BillingViewModel";


const OrganizationBilling = () => {
  const { organizationId } = useParams();
  const navigate = useNavigate();
  let vm = useBillingViewModel({
    organizationId: organizationId,
    eventBus: useEventBus(),
    organizationState: useLocation().state
  });

  useEffect(() => {
    vm.refreshOrganization();
  }, [vm, organizationId]);

  useEffect(() => {
    let sub = vm.subscribeToOrganizationChange();

    return () => sub.unsubscribe();
  }, [vm, organizationId]);

  const navigateToPlanChoice = useCallback(() => {
    navigate(`/organizations/${organizationId}/plans`);
  }, [organizationId, navigate]);

  const buttonProps = {
    title: "Change Plan",
    onClick: navigateToPlanChoice
  };

  return (
    <>
      <h1>Billing: {vm.organization?.organizationName}</h1>
      <p className="subtitle">View and configure your current RowHero subscription.</p>
      <SettingsContainer
        title="Subscription Information"
        buttonProps={buttonProps}>
        <SettingRow title="Plan" value={vm.organization?.planType} />
        <SettingRow title="Rower Count Tier" value={vm.rowerSubscriptionTier} />
        <SettingRow title="Expiration Date" value={vm.subscriptionExpiration} />
        <a className="low-emphasis" href={GlobalConfig.RowHeroBillingManagementUri}>Manage Subscription</a>
      </SettingsContainer>
    </>
  )
};

export default OrganizationBilling;