export default class UserInfo {
  private static _personId?: string;

  static isAuthenticated = (): boolean => {
    return !!UserInfo._personId;
  }
  
  static getPersonId = (): string | undefined => {
    return UserInfo._personId;
  }

  static getPersonIdUnsafe = (): string => {
    return UserInfo._personId!;
  }

  static setPersonId = (personId?: string) => {
    UserInfo._personId = personId;
  }
}