import OrganizationView from "../../../services/rowhero-api/model/OrganizationView";
import EventBusMessage from "../EventBusMessage";
import MessageNames from "./MessageNames";

export default class OwnedOrganizationsLoadedMessage implements EventBusMessage {
  get eventName() { return MessageNames.OwnedOrganizationsLoadedMessage; }

  constructor(public organizations: OrganizationView[]) {

  }
}