import { EventLogger } from "./DataDogEventLogger";
import GlobalConfig from '../../config/GlobalConfig';
import { LogLevel } from "./LogLevel";
import RowHeroWebEvent from "./RowHeroWebEvent";
import Bugsnag from "@bugsnag/js";

export default class AppEvents {
  public static trace(event: RowHeroWebEvent) {
    let metadata = event.createMetadata();

    EventLogger.log(event);

    if (GlobalConfig.IsDev) {
      if (!event.logInDevMode) {
        return;
      }
      
      let fun = console.log;
      switch (event.logLevel) {
        case LogLevel.Warn:
          fun = console.warn;
          break;
        case LogLevel.Error:
          fun = console.error;
          break;
      }

      fun(`${event.name}: ${JSON.stringify(metadata)}`);
      return;
    }

    Bugsnag.leaveBreadcrumb(event.name, metadata);
  }

  public static notifyDevelopers(issue: string, metadata: { [key: string]: any }) {
    if (GlobalConfig.IsDev) {
      console.warn('Issue notification sent: ' + issue);
      return;
    }

    Bugsnag.notify(new UserIdentifiedError(issue), report => {
      report.addMetadata("custom", metadata);
    });
  }
}

class UserIdentifiedError extends Error {

}