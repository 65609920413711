import { useState } from "react"

export interface ReactObservable<T> {
  (): T
  set: (value: T) => void;
  update: (props: Partial<T>) => void;
}

export function useObservableState<T = undefined>(): ReactObservable<T | undefined>;
export function useObservableState<T>(defaultValue: T): ReactObservable<T>;
export function useObservableState<T>(defaultValue?: T): ReactObservable<T> {
  // if (GlobalConstants.RunningUnderJest) {
  //   return useMockObservableState<T>(defaultValue);
  // }

  const [state, setState] = useState(defaultValue);

  // HACK: In case someone tries to access our value before React processes the
  // change from state to newState after a set or update call, we need to
  // handle updating the internal "state" ourselves.
  let updatedState = state;

  return Object.assign(
    () => updatedState,
    {
      set: (value: T) => {
        setState(value);
        updatedState = value;
      },
      update: (props: Partial<T>) => {
        const newState: T = {
          ...updatedState,
          ...props
        } as T;

        // Local update to state happens first, but don't use
        // newState for setState, because changes are async.
        // We don't want to overwrite existing state changes
        // that haven't been processed by the React dispatcher yet.
        updatedState = newState;

        setState(s => {
          if (!s) {
            return undefined;
          }

          const newState = {
            ...s,
            ...props
          };

          updatedState = newState;

          return newState;
        });
      }
    }
  ) as any;
}

export const useMockObservableState = <T>(defaultValue?: T): ReactObservable<T> => {
  let state = defaultValue;

  return Object.assign(
    () => state,
    {
      set: (value: T) => {
        state = value
      },
      update: (props: Partial<T>) => {
        state = {
          ...state,
          ...props
        } as T;
      }
    }
  ) as any;
}