import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';
import GlobalConfig from './config/GlobalConfig';
import ProtectedComponent from './components/ProtectedComponent/ProtectedComponent';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Root from './routes/root';
import Index from './routes';
import { RowHeroProvider } from './common/providers/RowHeroContextProvider';
import OrganizationBilling from './routes/organizations/billing';
import BillingPlanIndex from './routes/organizations/plans';
import ConfigurePlan from './routes/organizations/plans/configure';
import { datadogLogs } from '@datadog/browser-logs';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance';
import { ReactRouterRoutingProvider } from '@bugsnag/react-router-performance';


if (!GlobalConfig.IsDev) {
  Bugsnag.start({
    apiKey: GlobalConfig.BugsnagApiKey,
    plugins: [new BugsnagPluginReact()]
  });
  
  Bugsnag.getPlugin('react')?.createErrorBoundary(React);
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const routes = [
  {
    path: "/",
    element: <Root />,
    children: [
      { index: true, element: <Index /> },
      {
        path: "organizations/:organizationId/billing",
        element: <OrganizationBilling />
      },
      {
        path: "organizations/:organizationId/plans",
        element: <BillingPlanIndex />,
      },
      {
        path: "organizations/:organizationId/plans/configure",
        element: <ConfigurePlan />,
      },
    ]
  }
];

const router = createBrowserRouter(routes);

if (!GlobalConfig.IsDev) {
  BugsnagPerformance.start({
    apiKey: GlobalConfig.BugsnagApiKey,
    routingProvider: new ReactRouterRoutingProvider(routes, "/")
  });
}


root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={GlobalConfig.Auth0Domain}
      clientId={GlobalConfig.Auth0ClientId}
      authorizationParams={{
        redirect_uri: window.location.origin
      }}
      >
      <RowHeroProvider>
        <ProtectedComponent>
          <RouterProvider router={router} />
        </ProtectedComponent>
      </RowHeroProvider>
    </Auth0Provider>
  </React.StrictMode>
);

if (!GlobalConfig.IsDev) {
  datadogLogs.init({
    clientToken: GlobalConfig.DataDogClientToken,
    site: GlobalConfig.DataDogSite,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
