import TokenClaims from "./TokenClaims";
import RowHeroClaims from "./RowHeroClaims";
import { jwtDecode } from "jwt-decode";

export default class TokenDecoder {
  public static decodeToken = (token: string): TokenClaims => {
    let decoded = jwtDecode(token) as any;
    
    return new TokenClaims(
      decoded[RowHeroClaims.PersonId] || '',
      decoded[RowHeroClaims.TeamIds] || [],
      decoded[RowHeroClaims.CoachedTeamIds] || []
    );
  }
}