import { useEffect } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import useEventBus from '../common/events/EventBus';
import { useRowHeroContext } from '../common/providers/RowHeroContextProvider';
import '../index.css';
import AppEvents from '../services/analytics/AppEvents';
import LocationChangedEvent from '../services/analytics/LocationChangedEvent';
import useRootViewModel from './RootViewModel';
import Header from './components/Header';

const Root = () => {
  const eventBus = useEventBus();
  const location = useLocation();
  const vm = useRootViewModel({
    eventBus: eventBus
  });
  const { getRowHeroHttpClient } = useRowHeroContext();

  useEffect(() => {
    AppEvents.trace(new LocationChangedEvent(location.pathname, location.search));
  }, [location]);

  useEffect(() => {
    getRowHeroHttpClient().then(vm.load);
  }, [getRowHeroHttpClient, vm]);

  return (
    <>
      <Header />
      <div id="body-container">
        <div id="sidebar">
          <h2>Organizations</h2>
          <nav>
            <ul>
              {vm.organizations?.map(o => 
                (
                  <li key={o.organizationId}>
                    <Link
                      state={o}
                      to={`organizations/${o.organizationId}/billing`}>
                      {o.organizationName}
                    </Link>
                  </li>
                )
              ) ?? <em>Loading...</em>}
            </ul>
          </nav>
        </div>
        <div id="detail">
          <Outlet />
        </div>
      </div>
    </>
  )
};

export default Root;