import { ChangeEvent, PropsWithChildren, useCallback, useState } from "react";

interface SettingFieldDateProps {
  title: string;
  ariaLabel: string;
  inputName: string;

  required?: boolean;
  /**
   * Must be in the format `yyyy-mm-dd`
   */
  minDate?: string;
  /**
   * Must be in the format `yyyy-mm-dd`
   */
  maxDate?: string;

  /**
   * Must be in the format `yyyy-mm-dd`
   */
  defaultValue?: string;
  onChange?: (value?: string) => void;
}

const SettingFieldDate = (props: PropsWithChildren<SettingFieldDateProps>) => {
  let [value, setValue] = useState(props.defaultValue);
  let { onChange } = props;

  const onInputChange = useCallback((evt: ChangeEvent<HTMLInputElement>) => {
    setValue(evt.target.value);

    if (onChange) {
      onChange(value);
    }
  }, [onChange, value]);

  return (
    <div className="stat-box">
      <span className="stat-box-title">{props.title}</span>
      <span className="stat-box-value">
        <input
          type="date"
          aria-label={props.ariaLabel}
          name={props.inputName}
          min={props.minDate}
          max={props.maxDate}
          value={value}
          required={props.required}
          onChange={onInputChange}>
        </input>
        {props.children}
      </span>
    </div>
  );
};

export default SettingFieldDate;