import UserInfo from "../auth/UserInfo";
import { LogLevel } from "./LogLevel";

export default class RowHeroWebEvent {
  [key: string]: any;
  logLevel: LogLevel = LogLevel.Info;
  
  public appState?: string;
  public logInDevMode = true;

  constructor(
    public name: string
  ) {

  }

  public createMetadata = () : { [key: string]: any } => {
    let metadata: { [key: string]: any }  = {
      personId: UserInfo.getPersonId(),
      deviceModel: this.deviceModel
    };

    Object.keys(this)
      .filter(v => v !== 'name' && v !== 'createMetadata' && v !== "logInDevMode")
      .forEach(v => metadata[v] = this[v])

    return metadata;
  }

  public setFields = (params?: any, serializeObjects?: boolean) => {
    if (!params) {
      return;
    }

    for (let param of Object.keys(params)) {
      const obj = params[param];
      if (typeof obj === "function") {
        continue;
      }

      if (!serializeObjects && typeof obj === "object") {
        continue;
      }
      
      let value = typeof obj === "string" ? obj : JSON.stringify(obj);
      if (value && value.length > 100) {
        value = value.substring(0, 100);
      }
      
      this[param] = value;
    }
  }
}