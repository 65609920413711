import { useAuth0 } from "@auth0/auth0-react";
import { useCallback } from "react";
import { LuLogOut } from "react-icons/lu";

interface AccountMenuProps {
  visible?: boolean;
  onDismissRequested: () => void;
}

const AccountMenu = (props: AccountMenuProps) => {
  const { logout } = useAuth0();
  const { onDismissRequested } = props;

  const logOut = useCallback(() => {
    logout({
      logoutParams: {
        returnTo: window.location.origin
      }
    });
  }, [logout]);

  const dismissModal = useCallback(() => {
    onDismissRequested();
  }, [onDismissRequested]);

  return (
    <div className={`pop-up-menu-container ${props.visible ? "visible" : undefined}`} onClick={dismissModal}>
      <nav id="account-menu" className={props.visible ? "visible" : undefined}>
        <ul>
          <li>
            <button onClick={logOut}>
              <LuLogOut className="" />
              Sign Out
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default AccountMenu;