import { firstValueFrom } from "rxjs";
import GlobalConfig from "../../../config/GlobalConfig";
import HttpClient from "../../http/HttpClient";
import { mapJson } from "../../http/RxOperators";
import ProfileDataResponse from "../model/ProfileDataResponse";

export default class ProfileQuery {
  queryAsync(httpClient: HttpClient): Promise<ProfileDataResponse> {
    const uri = `${GlobalConfig.RowHeroApiBaseUri}/v1/me/profile`;
    
    const o = httpClient.getAsync(uri)
      .pipe(
        mapJson<ProfileDataResponse>()
      );

    return firstValueFrom(o);
  }
}