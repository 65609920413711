import { Subscription } from "rxjs";
import EventBusMessage from "./EventBusMessage";
import MessageHandler from "./MessageHandler";
import MessageHandlerList from "./MessageHandlerList";

export class EventBus {
  private handlers: Map<string, MessageHandlerList> = new Map<string, MessageHandlerList>();

  publish = (message: EventBusMessage) => {
    let handlers = this.handlers.get(message.eventName);
    if (!handlers) {
      return;
    }

    handlers.raise(message);
  }

  subscribe = <T extends EventBusMessage>(eventName: string, handler: MessageHandler<T>): Subscription => {
    let handlers: MessageHandlerList | undefined = this.handlers.get(eventName);
    if (!handlers) {
      handlers = new MessageHandlerList();
      this.handlers.set(eventName, handlers);
    }

    return handlers.add(handler);
  }
}

const bus = new EventBus();
const useEventBus = () => bus;

export default useEventBus;