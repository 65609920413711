import { useAuth0 } from "@auth0/auth0-react";
import React, { useCallback, useContext, useEffect } from "react";
import AppEvents from "../../services/analytics/AppEvents";
import { UnhandledErrorEvent } from "../../services/analytics/UnhandledErrorEvent";
import useAuthService from "../../services/auth/Auth0AuthService";
import DefaultRetryHttpClient from "../../services/http/DefaultRetryHttpClient";
import RowHeroHttpClient from "../../services/http/RowHeroHttpClient";

type RowHeroClientProviderType = {
  getRowHeroHttpClient: () => Promise<RowHeroHttpClient>
};

let defaultContext: RowHeroClientProviderType = {
  getRowHeroHttpClient: () => Promise.resolve(undefined as any)
};

export const RowHeroClientContext = React.createContext(defaultContext);
export const useRowHeroContext = () => useContext(RowHeroClientContext);

export const RowHeroProvider = ({ children }: { children: any }) => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const authService = useAuthService();

  useEffect(() => {
    authService.initialize(getAccessTokenSilently, loginWithRedirect);
  }, [authService, getAccessTokenSilently, loginWithRedirect]);

  let getRowHeroHttpClient = useCallback(async () => {
    try {
      let token = await authService.getFreshAccessToken();

      let newValue = new RowHeroHttpClient(
        token,
        new DefaultRetryHttpClient(),
        authService);

      return newValue;
    } catch (e: any) {
      AppEvents.trace(new UnhandledErrorEvent(window.location, e));
      return undefined as any;
    }

  }, [authService]);
  

  let value = {
    getRowHeroHttpClient: getRowHeroHttpClient
  };

  return (
    <RowHeroClientContext.Provider value={value}>
      {children}
    </RowHeroClientContext.Provider>
  )
}