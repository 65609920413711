import { DateTime } from "luxon";
import { useState } from "react";
import { Subscription } from "rxjs";
import { EventBus } from "../../common/events/EventBus";
import MessageNames from "../../common/events/messages/MessageNames";
import OwnedOrganizationsLoadedMessage from "../../common/events/messages/OwnedOrganizationsLoadedMessage";
import { useObservableState } from "../../common/react-observable/ReactObservable";
import OrganizationView from "../../services/rowhero-api/model/OrganizationView";

interface BillingState {
  organization?: OrganizationView;
}

class BillingViewModelParams {
  organizationId?: string;
  organizationState?: OrganizationView;
  eventBus!: EventBus;
};

export class BillingViewModel extends BillingViewModelParams {
  private state = useObservableState<BillingState>({});
  
  get organization(): OrganizationView | undefined {
    return this.state().organization;
  }

  get rowerSubscriptionTier(): string | undefined {
    let organization = this.state().organization;
    if (!organization?.rowerSubscriptionTier) {
      return undefined;
    }

    return organization.rowerSubscriptionTier?.replace(" to ", " – ");
  }

  get subscriptionExpiration(): string | undefined {
    let organization = this.state().organization;
    if (!organization?.subscriptionExpiration) {
      return undefined;
    }

    return DateTime.fromISO(organization.subscriptionExpiration).toLocaleString(DateTime.DATE_FULL) ?? undefined;
  }

  refreshOrganization = () => {
    if (this.organizationState && this.organizationState !== this.organization) {
      this.state.update({
        organization: this.organizationState
      });
    }
  }

  subscribeToOrganizationChange = (): Subscription => {
    let sub = this.eventBus.subscribe(
      MessageNames.OwnedOrganizationsLoadedMessage,
      (msg: OwnedOrganizationsLoadedMessage) => {
        for (let i = 0; i < msg.organizations.length; ++i) {
          if (msg.organizations[i].organizationId === this.organizationId) {
            this.state.update({
              organization: msg.organizations[i]
            });

            break;
          }
        }
      });

    return sub;
  }
}

const useBillingViewModel = (params: BillingViewModelParams)
  : BillingViewModel => {

  const defaultVm = new BillingViewModel();
  const [vm] = useState(defaultVm);

  Object.assign(vm, params);

  return vm;
}

export default useBillingViewModel;