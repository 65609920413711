interface StepProgressBarStepProps {
  state: StepProgressBarStepState;
  stepName: string;
  
  showLeadingLine?: boolean;
  showTrailingLine?: boolean;
}

export type StepProgressBarStepState = "current" | "completed" | "future";

const StepProgressBarStep = (props: StepProgressBarStepProps) => {
  return (
    <div className="step-progress-bar-step">
      <div className="step-line">
        <span className={`line${!props.showLeadingLine ? "-hidden" : ""}`}></span>
        <span className={`dot-${props.state}`}></span>
        <span className={`line${!props.showTrailingLine ? "-hidden" : ""}`}></span>
      </div>
      <div className={`step-title ${props.state}`}>{props.stepName}</div>
    </div>
  );
};

export default StepProgressBarStep;