import { MonoTypeOperatorFunction, Observable, OperatorFunction, from, of, throwError, timer } from "rxjs";
import { catchError, retry, switchMap } from "rxjs/operators";
import ApiError from "./ApiError";

export function mapJson<T>() : OperatorFunction<Response | undefined, T> {
  return (observable: Observable<Response | undefined>) => observable.pipe(
    switchMap(response => {
      if (response) {
        return _extractJson<T>(response);
      }

      return of(undefined);
    })
  );
}

// export function decompressStrokeData() : OperatorFunction<Response | undefined, StrokeDataBlob> {
//   return (observable: Observable<Response | undefined>) => observable.pipe(
//     switchMap(response => response && _extractText(response) || of(undefined)),
//     switchMap(_decompressIfNecessary)
//   );
// }

// const _extractText = (response: Response): Observable<string> => {
//   return from(response.text());
// };

// const _decompressIfNecessary = (text?: string): Observable<StrokeDataBlob> => {
//   if (!text) {
//     return of({
//       pieceStrokes: []
//     });
//   }

//   if (text.startsWith("[")) {
//     return of({
//       pieceStrokes: <StrokeRecordWireModel[]>JSON.parse(text)
//     });
//   }

//   const { inflate } = require('react-zlib-js');
   
//   const promise = new Promise<StrokeDataBlob>((resolve, reject) => {

//     inflate(Buffer.from(text, 'base64'), (error: any, buffer: Buffer) => {
//       if (error) {
//         reject(error);
//         return;
//       }

//       let text = buffer.toString("utf8");
//       let strokeData: StrokeDataBlob = { pieceStrokes: [] };
//       if (text.startsWith("[")) {
//         strokeData.pieceStrokes = <StrokeRecordWireModel[]>JSON.parse(text);
//       } else {
//         strokeData = <StrokeDataBlob>JSON.parse(text);
//       }

//       resolve(strokeData);
//     })
//   });

//   return from(promise);
// }

const _extractJson = <T>(response: Response): Observable<any> => {
  if (response.ok && response.status !== 204) {
    // DEBUG AID
    // return from(response.text()).pipe(
    //   switchMap((text: string) => {
    //     console.log("RESPONSE TEXT: " + text);
    //     return of(JSON.parse(text));
    //   })
    // );

    return from(response.json() as Promise<T>);
  }

  return throwError(() => new Error("Response did not indicate success. Extracting JSON failed."));
}

export function standardRetry(): MonoTypeOperatorFunction<Response> {
  return retry({ delay: (error: ApiError, retryCount: number) => {
    if (error instanceof ApiError) {
      const retryAttempt = retryCount + 1;
      const status = error.status;

      if (status <= 500 || retryAttempt > 2) {
        return throwError(() => error);
      }

      return timer(1000 * retryAttempt);
    }

    // Errors (not ApiErrors) are thrown by fetch due to errors in the network stack (e.g. offline, DNS not resolvable...)
    // Don't retry in these cases.
    return throwError(() => error);
  } }); 
}

export function ignore404(): OperatorFunction<Response, Response | undefined> {
  return catchError((err: ApiError) => {
    if (err.status === 404) {
      return of(undefined);
    }

    return throwError(() => err);
  });
}