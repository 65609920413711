import { useCallback, useState } from 'react';
import Images from '../../common/images/Images';
import logo from '../../resources/images/LogoWhiteTransparent.png';
import UserInfo from '../../services/auth/UserInfo';
import AccountMenu from './AccountMenu';

const Header = () => {
  let [showAccountMenu, setShowAccountMenu] = useState(false);
  let personId = UserInfo.getPersonIdUnsafe();
  let [profileImage, setProfileImage] = useState(Images.personProfileImageFromId(personId));
  
  let onClick = useCallback(() => {
    setShowAccountMenu(!showAccountMenu);
  }, [showAccountMenu]);

  let hideMenu = useCallback(() => {
    setShowAccountMenu(false);
  }, []);

  let onImgError = useCallback(() => {
    setProfileImage(Images.FallbackProfileImage);
  }, [setProfileImage]);

  return (
    <>
      <header>
        <img src={logo} id="app-logo" alt="logo" />
        <div id="profile-image">
          <button onClick={onClick}>
            {personId ? <img src={profileImage} alt="Your profile image" onError={onImgError} /> : null}
          </button>
        </div>
        <AccountMenu
          visible={showAccountMenu}
          onDismissRequested={hideMenu} />
      </header>
    </>
  )
};

export default Header;