import { ButtonImportance } from "./ButtonImportance";

interface SubmitButtonProps extends React.InputHTMLAttributes<HTMLInputElement> {
  importance: ButtonImportance;
}


const SubmitButton = (props: SubmitButtonProps) => {
  return (
    <input type="submit" className={`${props.className ?? ""} ${props.importance}`} {...props}></input>
  )
};

export default SubmitButton;