import { AppState, GetTokenSilentlyOptions, RedirectLoginOptions } from "@auth0/auth0-react";
import Bugsnag from "@bugsnag/js";
import GlobalConfig from "../../config/GlobalConfig";
import AppEvents from "../analytics/AppEvents";
import LoginEvent from "../analytics/LoginEvent";
import OAuthService from "./OAuthService";
import TokenDecoder from "./TokenDecoder";
import UserInfo from "./UserInfo";

export class Auth0AuthService implements OAuthService {
  private accessToken?: string;
  private getAccessTokenSilently?: (options?: GetTokenSilentlyOptions) => Promise<GetTokenSilentlyOptions | string>;
  private loginWithRedirect?: (options?: RedirectLoginOptions<AppState> | undefined) => Promise<void>;

  initialize(
    getAccessTokenSilently: (options?: GetTokenSilentlyOptions) => Promise<GetTokenSilentlyOptions | string>,
    loginWithRedirect: (options?: RedirectLoginOptions<AppState> | undefined) => Promise<void>
  ) {
    this.getAccessTokenSilently = getAccessTokenSilently;
    this.loginWithRedirect = loginWithRedirect;
  }

  getFreshAccessToken = (): Promise<string> => {
    if (!this.getAccessTokenSilently) {
      throw new Error("getAccessTokenSilently not initialized.")
    }

    return this.getAccessTokenSilently({
        authorizationParams: {
          audience: GlobalConfig.Auth0Audience,
        }
      })
      .then(this._parseToken)
      .catch(e => {
        // https://community.auth0.com/t/getaccesstokensilently-throws-error-login-required/52333/4
        if (e.error === "login_required" || e.error === "consent_required") {
          this.loginWithRedirect?.();
        }

        return Promise.reject(e);
      });
  }

  getToken = (): Promise<string> => {
    if (this.accessToken) {
      return Promise.resolve(this.accessToken);
    }

    return this.getFreshAccessToken();
  }

  private _parseToken = (accessToken: string | GetTokenSilentlyOptions): string => {
    this.accessToken = accessToken.toString();

    const personId = TokenDecoder.decodeToken(accessToken.toString()).personId;

    UserInfo.setPersonId(personId);

    AppEvents.trace(new LoginEvent(personId));
      
    // Bugsnag is not enabled on Dev
    if (!GlobalConfig.IsDev) {
      Bugsnag.setUser(personId);
    }

    return this.accessToken;
  }

}

const authService = new Auth0AuthService();
const useAuthService = () => authService;

export default useAuthService;