import GlobalConfig from "../../config/GlobalConfig";
import { datadogLogs } from "@datadog/browser-logs";
import RowHeroWebEvent from "./RowHeroWebEvent";
import { LogLevel } from "./LogLevel";



export class EventLogger {
  static log = (event: RowHeroWebEvent) => {
    if (GlobalConfig.IsDev) {
      return;
    }

    let log = undefined;
    switch (event.logLevel) {
      case LogLevel.Info:
        log = datadogLogs.logger.info;
        break;
      case LogLevel.Debug:
        log = datadogLogs.logger.debug;
        break;
      case LogLevel.Error:
        log = datadogLogs.logger.error;
        break;
      case LogLevel.Warn:
        log = datadogLogs.logger.warn;
        break;
    }

    log.call(datadogLogs.logger, event.name, event.createMetadata());
  }
}