import { Observable, Observer, from, of, throwError } from "rxjs";
import { switchMap } from "rxjs/operators";
import MakingWebRequestEvent from "../analytics/MakingWebRequestEvent";
import ApiError from "./ApiError";
import HttpClient from "./HttpClient";

interface InstrumentedResponse {
  response: Response
  correlationId: string
  elapsedTime: number
}

export default class FetchHttpClient implements HttpClient {
  constructor(private simulateOffline?: boolean) {

  }

  deleteAsync(uri: string, headers?: { [key: string]: string }): Observable<Response> {
    return this._createDefaultPipeline(this._makeRequest(uri, "DELETE", undefined, headers));
  } 
  
  getAsync(uri: string, headers?: { [key: string]: string }): Observable<Response> {
    return this._createDefaultPipeline(this._makeRequest(uri, 'GET', undefined, headers));
  } 
  
  patchAsync(uri: string, body: any, headers?: { [key: string]: string }): Observable<Response> {
    return this._createDefaultPipeline(this._makeRequest(uri, 'PATCH', body, headers));
  }

  postAsync(uri: string, body: any, headers?: { [key: string]: string }): Observable<Response> {
    return this._createDefaultPipeline(this._makeRequest(uri, 'POST', body, headers));
  }

  putAsync(uri: string, body: any, headers?: { [key: string]: string }): Observable<Response> {
    return this._createDefaultPipeline(this._makeRequest(uri, 'PUT', body, headers));
  }

  _createDefaultPipeline = (observable: Observable<InstrumentedResponse>): Observable<Response> => {
    return observable.pipe(
      switchMap((r: InstrumentedResponse) => {
        const response = r.response;
        if (response.status >= 400) {
          return from(response.text())
            .pipe(
              switchMap(text => {
                // if (response.status !== 404 && response.status !== 401) {
                //   AppEvents.trace(new WebRequestFailedEvent(response.url, r.correlationId, response.status, r.elapsedTime, text));
                // }

                return throwError(() => new ApiError(response.url, response.status, text));
              })
            );
        }

        return of(response);
      })
    );
  }

  private _makeRequest = (uri: string, reqMethod: string, body?: any, headers?: { [key: string]: string }): Observable<InstrumentedResponse> => {
    return new Observable((observer: Observer<InstrumentedResponse>) => {
      if (this.simulateOffline) {
        observer.error(new TypeError("Network request failed"));
        return;
      }

      let requestHeaders = headers || {};
      // requestHeaders["X-AppVersion"] = `${Platform.OS}:${DeviceInfo.getVersion()}`;

      if (body) {
        if (typeof body === "string") {
          requestHeaders["Content-Type"] = "application/x-www-form-urlencoded";
        } else if (!(body instanceof FormData)) {
          requestHeaders["Content-Type"] = "application/json";
        } 
      }
      
      const bodyString = body ? ((typeof body === "string") || (body instanceof FormData) ? body : JSON.stringify(body)) : undefined;


      // const skipLog = !!requestHeaders["Skip-Log"];
      delete requestHeaders["Skip-Log"];

      const timeoutOverride = requestHeaders["timeoutOverride"] ? parseInt(requestHeaders["timeoutOverride"]) : undefined;
      delete requestHeaders["timeoutOverride"];

      // console.log('REQUEST', uri, body, reqMethod);
      
      const event = new MakingWebRequestEvent(uri, reqMethod);

      // Tracing MakingWebRequestEvent leads to enormous event volumes.
      // if (!skipLog) {
      //   AppEvents.trace(event);
      // }
      
      requestHeaders["RowHero-CorrelationId"] = event.correlationId;
      const time = new Date().getTime();
      const abortController = new AbortController();
      let promise = fetch(uri,
        {
          method: reqMethod,
          headers: requestHeaders,
          body: bodyString,
          signal: abortController.signal
        });

      // NOTE: setTimeout is not respected in the background
      // for Android.
      const id = setTimeout(() => abortController.abort(), timeoutOverride || 10000);

      promise.then(response => {
        const elapsedTime = (new Date().getTime()) - time;
        clearTimeout(id);

        const instrumentedResponse = {
          response: response,
          correlationId: event.correlationId,
          elapsedTime: elapsedTime
        };

        observer.next(instrumentedResponse);
        observer.complete();

        // Tracing WebResponseReceived leads to enormous event volumes.
        // if (!skipLog && response.status < 400) {
        //   AppEvents.trace(new WebResponseReceivedEvent(uri, event.correlationId, response.status, elapsedTime));
        // }
      })
      .catch(error => {
        observer.error(error);
        observer.complete();
      });
    });
  }
}