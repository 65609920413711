export default class GlobalConfig {
  static IsDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

  static Auth0ClientId = "WoMNbS9a7hZPIf5bgmBZ1xt8A9Pt6vMs";
  static Auth0Domain = "rowhero.auth0.com";
  static Auth0Audience = "https://api.rowhero.com";
  static Auth0Scope = "openid profile email read:workouts update:workouts create:pieces delete:pieces create:heartbeats read:heartbeats update:teams read:teams read:pieces export:pieces offline_access";

  static BugsnagApiKey = "991e517ef7df72e9f2bb14c7daa7fbac";

  static DataDogClientToken = "pub1308820e295f07661d0cce78d9d63b5f";
  static DataDogSite = "datadoghq.com";



  static RowHeroApiBaseUri = "https://rowhero-api-prod.azurewebsites.net";
  static RowHeroBillingManagementUri = "https://rowhero-billing.chargebeeportal.com/";
}