import RowHeroWebEvent from "./RowHeroWebEvent";

// Create UnhandledErrorEvent deriving from RowHeroWebEvent
export class UnhandledErrorEvent extends RowHeroWebEvent {
  errorName?: string;
  errorMessage?: string;
  errorDetails?: string;
  url: string;

  constructor(url: Location, error: Error) {
    super("UnhandledErrorEvent");

    this.url = url.pathname + url.search;

    this.errorName = error.name;
    this.errorMessage = error.message || error.toString();

    if (typeof error === "object") {
      this.errorDetails = JSON.stringify(error);
    }
  }
}