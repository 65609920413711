import { useAuth0 } from "@auth0/auth0-react";
import React, { PropsWithChildren } from "react"
import GlobalConfig from "../../config/GlobalConfig";

interface ProtectedComponentProps {

}

const ProtectedComponent = (props: PropsWithChildren<ProtectedComponentProps>) => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    loginWithRedirect({
      authorizationParams: {
        redirect_uri: window.location.origin,
        audience: GlobalConfig.Auth0Audience,
      }
    });
    return <div>Redirecting to login...</div>;
  }

  return (
    <>
      {props.children}
    </>
  );
};

export default ProtectedComponent;