import { DateTime } from "luxon";
import { useState } from "react";
import { ReactObservable, useObservableState } from "../../../common/react-observable/ReactObservable";
import { Auth0AuthService } from "../../../services/auth/Auth0AuthService";

interface ConfigureViewModelState {
  accessToken?: string;
  rowerCount: number;
  subscriptionLength: string;
  subscriptionStartDate?: string;
}

class ConfigureViewModelParams {
  planCode!: string;
  authService!: Auth0AuthService
};

export class ConfigureViewModel extends ConfigureViewModelParams {
  private state: ReactObservable<ConfigureViewModelState>;
  

  constructor(initialState: ConfigureViewModelState) {
    super();

    // eslint-disable-next-line react-hooks/rules-of-hooks
    this.state = useObservableState<ConfigureViewModelState>(initialState);
  }

  init = () => {
    this.authService.getToken()
      .then(token => this.state.update({
        accessToken: token
      }));
  }
  
  get accessToken(): string | undefined {
    return this.state().accessToken;
  }

  get rowerCount(): number {
    return this.state().rowerCount;
  }

  get subscriptionLength(): string {
    return this.state().subscriptionLength;
  }

  get subscriptionStartDate(): string | undefined {
    return this.state().subscriptionStartDate;
  }

  get minStartDate(): string {
    return DateTime.now().toISODate();
  }

  get planType(): string {
    return this.planCode === "High-Performance" ? "HighPerformance" : "Essentials";
  }

  get planTypeFriendlyName(): string {
    return this.planCode === "High-Performance" ? "High Performance" : "Essentials";
  }

  checkout = () => {
    let checkoutUrl = "https://rowhero-test.chargebee.com/hosted_pages/checkout?layout=full_page";
    let subscriptionPlan = `${this.planCode}-USD-${this.subscriptionLength}`;
    let rowerCount = this.rowerCount.toString();

    checkoutUrl += `&subscription_items[item_price_id][0]=${subscriptionPlan}&subscription_items[quantity][0]=${rowerCount}`;

    window.location.href = checkoutUrl;
  }

  onRowerCountChanged = (value?: number) => {
    this.state.update({
      rowerCount: value
    });
  }

  onSubscriptionLengthChanged = (value: string) => {
    this.state.update({
      subscriptionLength: value
    });
  }

  onSubscriptionStartDateChanged = (value?: string) => {
    this.state.update({
      subscriptionStartDate: value
    });
  }
}

const useConfigureViewModel = (params: ConfigureViewModelParams, initialState: ConfigureViewModelState)
  : ConfigureViewModel => {
  const defaultVm = new ConfigureViewModel(initialState);
  const [vm] = useState(defaultVm);

  Object.assign(vm, params);

  return vm;
}

export default useConfigureViewModel;