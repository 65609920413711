import HttpClient from './HttpClient';
import { Observable } from 'rxjs';
import { standardRetry } from './RxOperators';
import FetchHttpClient from './FetchHttpClient';

export default class DefaultRetryHttpClient implements HttpClient {
  private httpClient: HttpClient

  constructor(httpClient?: HttpClient) {
    this.httpClient = httpClient || new FetchHttpClient();
  }

  deleteAsync(uri: string, headers?: { [key: string]: string; }): Observable<Response> {
    return this._createDefaultPipeline(this.httpClient.deleteAsync(uri, headers));
  }

  getAsync(uri: string, headers?: { [key: string]: string; }): Observable<Response> {
    return this._createDefaultPipeline(this.httpClient.getAsync(uri, headers));
  }
  
  patchAsync(uri: string, body: any, headers?: { [key: string]: string; }): Observable<Response> {
    return this._createDefaultPipeline(this.httpClient.patchAsync(uri, body, headers));
  }

  postAsync(uri: string, body: any, headers?: { [key: string]: string; }): Observable<Response> {
    return this._createDefaultPipeline(this.httpClient.postAsync(uri, body, headers));
  }

  putAsync(uri: string, body: any, headers?: { [key: string]: string; }): Observable<Response> {
    return this._createDefaultPipeline(this.httpClient.putAsync(uri, body, headers));
  }

  _createDefaultPipeline = (observable: Observable<Response>) => {
    return observable.pipe(
      standardRetry()
    )
  }
}