import RowHeroWebEvent from "./RowHeroWebEvent";

export default class MakingWebRequestEvent extends RowHeroWebEvent {
  correlationId: string;

  constructor(public uri: string,
    public method: string) {
      super("MakingWebRequestEvent");
      this.logInDevMode = false;

      this.correlationId = "web-" + Math.random().toString(36).substring(2, 15);
    }
}