export default class ApiError extends Error {
  constructor(public uri: string, public status: number, public serviceText: string, public userMessage?: string) {
    super(status + "/" + serviceText);
    this.name = "ApiError";
  }

  bodyAsJson(): any {
    try {
      return JSON.parse(this.serviceText);
    } catch (e) {
      return undefined;
    }
  }

  debugMessage(): string {
    return this.status + "/" + this.serviceText;
  }

  toString(): string {
    return this.debugMessage();
  }
}