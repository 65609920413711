import { Observable, from } from 'rxjs';
import { retry } from 'rxjs/operators';
import OAuthService from '../auth/OAuthService';
import ApiError from './ApiError';
import HttpClient from './HttpClient';
import { standardRetry } from './RxOperators';

export default class RowHeroHttpClient implements HttpClient {
  constructor(public accessToken: string, private httpClient: HttpClient, private authService: OAuthService) {

  }

  deleteAsync(uri: string, headers?: { [key: string]: string; }): Observable<Response> {
    headers = this._initializeHeaders(headers);

    return this._createDefaultPipeline(this.httpClient.deleteAsync(uri, headers), headers);
  }

  getAsync(uri: string, headers?: { [key: string]: string; }): Observable<Response> {
    headers = this._initializeHeaders(headers);

    return this._createDefaultPipeline(this.httpClient.getAsync(uri, headers), headers);
  }
  
  patchAsync(uri: string, body: any, headers?: { [key: string]: string; }): Observable<Response> {
    headers = this._initializeHeaders(headers);

    return this._createDefaultPipeline(this.httpClient.patchAsync(uri, body, headers), headers);
  }

  postAsync(uri: string, body: any, headers?: { [key: string]: string; }): Observable<Response> {
    headers = this._initializeHeaders(headers);

    return this._createDefaultPipeline(this.httpClient.postAsync(uri, body, headers), headers);
  }

  putAsync(uri: string, body: any, headers?: { [key: string]: string; }): Observable<Response> {
    headers = this._initializeHeaders(headers);

    return this._createDefaultPipeline(this.httpClient.putAsync(uri, body, headers), headers);
  }

  private _initializeHeaders = (headers?: { [key: string]: string; }): { [key: string]: string; }  => {
    if (!headers) {
      headers = {};
    }

    headers["Authorization"] = "Bearer " + this.accessToken;

    return headers;
  }

  private _createDefaultPipeline = (observable: Observable<Response>, headers: { [key: string]: string; }) => {
    let enableRetry = true;
    if (headers["X-DoNotRetry"] === "1") {
      enableRetry = false;
      delete headers["X-DoNotRetry"];
    }

    if (!enableRetry) {
      return observable.pipe(
        retry({ delay: this._checkAuthentication(headers) })
      );
    }

    return observable.pipe(
      retry({ delay: this._checkAuthentication(headers) }),
      standardRetry()
    );
  }

  private _checkAuthentication = (headers: { [key: string]: string }): (error: ApiError, retryCount: number) => Observable<any> => {
    return (error, retryCount) => {
      if (error.status === 401 && retryCount < 2) {
        return from(this.authService.getFreshAccessToken()
          .then(token => {
            if (!token) {
              throw new Error('Could not refresh token.');
            }
  
            this.accessToken = token;
            headers['Authorization'] = 'Bearer ' + token;
          }));
      }

      throw error;
    };
  }

  // private _checkAuthentication = (headers: { [key: string]: string }): (error: Observable<ApiError>) => Observable<any> => {
  //   return (errors) => {
  //     return errors.pipe(
  //       switchMap((err, attempts) => {
  //         attempts = attempts + 1;
  //         if (err.status === 401 && attempts < 2) {
  //           return this.authService.getFreshAccessToken();
  //         }

  //         return throwError(() => err);
  //       }), tap(token => {
  //         if (!token) {
  //           throw new Error('Could not refresh token.');
  //         }

  //         this.accessToken = token;
  //         headers['Authorization'] = 'Bearer ' + token;
  //       })
  //     );
  //   };
  // }
}