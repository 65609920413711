import { PropsWithChildren } from "react";
import { ButtonImportance } from "./ButtonImportance";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  importance: ButtonImportance;
}

const Button = (props: PropsWithChildren<ButtonProps>) => {
  
  return (
    <button className={`${props.className} ${props.importance}`} {...props}>
      {props.children}
    </button>
  )
};

export default Button;