interface SettingRowProps {
  title: string;
  value?: string;
}

const SettingRow = (props: SettingRowProps) => {
  return (
    <div className="stat-box">
      <span className="stat-box-title">{props.title}</span>
      <span className="stat-box-value">{props.value}</span>
    </div>
  );
};

export default SettingRow;