import { useState } from "react";
import { useObservableState } from "../common/react-observable/ReactObservable";
import RowHeroHttpClient from "../services/http/RowHeroHttpClient";
import ProfileQuery from "../services/rowhero-api/queries/ProfileQuery";
import OrganizationView from "../services/rowhero-api/model/OrganizationView";
import { EventBus } from "../common/events/EventBus";
import OwnedOrganizationsLoadedMessage from "../common/events/messages/OwnedOrganizationsLoadedMessage";

interface RootState {
  organizations?: OrganizationView[];
}
class RootViewModelParams {
  eventBus!: EventBus;
};

export class RootViewModel extends RootViewModelParams {
  private state = useObservableState<RootState>({});

  get organizations(): OrganizationView[] | undefined {
    return this.state().organizations;
  }
  
  load = (httpClient: RowHeroHttpClient) => {
    new ProfileQuery().queryAsync(httpClient)
      .then(profile => {
        let organizations = profile.ownedOrganizations;
        if (!organizations) {
          return;
        }

        this.state.update({
          organizations: organizations
        });

        this.eventBus.publish(new OwnedOrganizationsLoadedMessage(organizations));
      });
  }
}

const useRootViewModel = (params: RootViewModelParams)
  : RootViewModel => {
  const defaultVm = new RootViewModel();
  const [vm] = useState(defaultVm);

  Object.assign(vm, params);

  return vm;
}

export default useRootViewModel;