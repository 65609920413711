import { PropsWithChildren } from "react";
import { FaRegCheckCircle } from "react-icons/fa";

interface PlanFeatureProps {

}

const PlanFeature = (props: PropsWithChildren<PlanFeatureProps>) => {
  return (
    <div className="plan-feature">
      <span className="plan-feature-check-container">
        <FaRegCheckCircle
          className="plan-feature-check"
          size={22} />
      </span>
      <div className="plan-feature-content">
        {props.children}
      </div>
    </div>
  )
};

export default PlanFeature;