import { MouseEventHandler, PropsWithChildren } from "react"
import Button from "../Button/Button";

interface SettingsContainerProps {
  title: string;
  buttonProps?: SettingsContainerButtonProps;
  
  className?: string;
}

export interface SettingsContainerButtonProps {
  title: string;
  onClick: MouseEventHandler<HTMLButtonElement>;

};

const SettingsContainer = (props: PropsWithChildren<SettingsContainerProps>) => {
  return (
    <div className={`settings-container ${props.className}`}>
      <div className="title-row">
        <h2>{props.title}</h2>
        {props.buttonProps && (
          <Button importance="secondary" onClick={props.buttonProps.onClick}>
            {props.buttonProps.title}
          </Button>
        )}
      </div>
      {props.children}
    </div>
  )
};

export default SettingsContainer;