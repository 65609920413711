import PlanFeature from "../PlanFeature/PlanFeature";

interface PlanFeatureListProps {
  features: string[];
}

const PlanFeatureList = (props: PlanFeatureListProps) => {
  return (
    <div className="plan-feature-list">
      {props.features.map((f, i) => (
        <PlanFeature key={`Feature-${i}`}>{f}</PlanFeature>
      ))}
    </div>
  )
};

export default PlanFeatureList;