import { Subscription } from "rxjs";
import MessageHandler from "./MessageHandler";
import EventBusMessage from "./EventBusMessage";

export default class MessageHandlerList {
  private subscriptionKeys: object[] = [];
  private handlers: WeakMap<object, MessageHandler<any>> = new WeakMap<object, MessageHandler<any>>();

  add<T extends EventBusMessage>(handler: MessageHandler<T>): Subscription {
    let key = {};
    this.subscriptionKeys.push(key);
    this.handlers.set(key, handler);

    return new Subscription(() => this.remove(key));
  }

  raise(message: EventBusMessage) {
    for (let i = this.subscriptionKeys.length - 1; i >= 0; --i) {
      let handler = this.handlers.get(this.subscriptionKeys[i]);
      if (handler) {
        handler.call(null, message);
      } else {
        this.subscriptionKeys.splice(i, 1);
      }
    }
  }

  remove(subscriptionKey: object) {
    for (let i = this.subscriptionKeys.length - 1; i >= 0; --i) {
      if (this.subscriptionKeys[i] === subscriptionKey) {
        this.subscriptionKeys.splice(i, 1);
        break;
      }
    }

    this.handlers.delete(subscriptionKey);
  }
}