import { ChangeEvent, PropsWithChildren, useCallback, useState } from "react";
import { KeyValuePair } from "../../common/KeyValuePair";

interface SettingFieldSelectProps {
  title: string;
  ariaLabel: string;
  inputName: string;
  options: KeyValuePair[];

  required?: boolean;

  defaultValue?: string;
  onChange?: (value: string) => void;
}

const SettingFieldSelect = (props: PropsWithChildren<SettingFieldSelectProps>) => {
  let [inputValue, setInputValue] = useState(props.defaultValue);
  let { onChange } = props;

  const onInputChange = useCallback((event: ChangeEvent<HTMLSelectElement>) => {
    let value = event.target.value;
    setInputValue(value);

    if (onChange) {
      onChange(value);
    }
  }, [onChange]);

  return (
    <div className="stat-box">
      <span className="stat-box-title">{props.title}</span>
      <span className="stat-box-value">
        <select
          aria-label={props.ariaLabel}
          name={props.inputName}
          required={props.required}
          value={inputValue}
          onChange={onInputChange}>
          {props.options.map(o => (
            <option
              key={`Option-${o[1]}`}
              title={o[0]}
              value={o[1]}>{o[0]}</option>
          ))}
        </select>
        {props.children}
      </span>
    </div>
  );
};

export default SettingFieldSelect;