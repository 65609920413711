import "./step-progress-bar.css";
import StepProgressBarStep from "./StepProgressBarStep";

interface StepProgressBarProps {
  stepNames: string[];
  currentIndex: number;
}

const StepProgressBar = (props: StepProgressBarProps) => {
  return (
    <div className="step-progress-bar">
      {props.stepNames.map((s, i) => (
        <StepProgressBarStep
          key={`Step-${i}`}
          state={props.currentIndex > i ? "completed" : props.currentIndex === i ? "current" : "future"}
          stepName={s}
          showLeadingLine={i !== 0}
          showTrailingLine={i !== props.stepNames.length - 1} />
      ))}
    </div>
  )
};

export default StepProgressBar;